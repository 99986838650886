const binding = { modules: {}, dataActions: {} };

    (binding.modules['starter'] = {
        c: () => require('@msdyn365-commerce-modules/starter-theme-pack/dist/lib/modules/starter/starter'),
        $type: 'themeModule',
        da: [],
        definitionExtensions: ['content-block','promo-banner','text-block'],
        iNM: true,
        ns: '@msdyn365-commerce-modules',
        n: 'starter',
        p: 'starter-theme-pack',
        
        pdp: '',
        
        themeSettings: 'starter.theme.settings.json',
        md: 'node_modules/@msdyn365-commerce-modules/starter-theme-pack/dist/lib/modules/starter'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };